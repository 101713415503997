<template>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false" >
        {{ $t('transportation.transportationInActive') }}
    </dashboard-page-title>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: { },
  mounted () {
    core.index()
  },
  created () {
    if (this.$route.params.id) {
      this.getDriver()
    //   this.$root.$on('showSuspensedModal', this.showSuspensedModal)
    }
  }
}
</script>
